'use strict';

import './datatables.mjs'

/**
 * JS-Dokument zu eBrowse
 */

/**
 * Schnellfilter
 *
 * Buttons zum Filtern einer DataTable anhand vordefinierter Filterbegriffe
 *
 * Findet alle Elemente .schnellfilter und wandelt diese zu Schnellfiltern
 * Oder alternativ Nutzung von Funktion schnellfilter(element);
 */
!function ($) {
    var groups = {};

    var SchnellfilterClick = function SchnellfilterClick(event) {
        var selectClass = $(this).data('filter-selectclass'); // hier class kleingeschrieben
        var spalte = $(this).data('filter-spalte');
        var query = $(this).data('filter-query');
        var dataTableSelector = $(this).data('filter-datatable'); // hier kleingeschrieben

        if ($(element).hasClass(selectClass) == false) {
            SchnellfilterReset(dataTableSelector, spalte);

            $(this).removeClass('grey');
            $(this).addClass(selectClass);

            $(self.tableSelector).dataTable({ "bRetrieve": true }).fnFilter(query, spalte, true);
        } else {
            SchnellfilterReset(dataTableSelector, spalte);
        }
    };

    var SchnellfilterReset = function SchnellfilterReset(dataTableSelector, resetSpalte) {
        $.each(groups[dataTableSelector][resetSpalte], function (index) {
            var selectClass = $(this).data('filter-selectclass'); // hier class kleingeschrieben
            var spalte = $(this).data('filter-spalte');

            if (spalte == resetSpalte && $(this).hasClass(selectClass)) {
                $(this).removeClass(selectClass);
                $(this).addClass('grey');

                $(dataTableSelector).dataTable({ "bRetrieve": true }).fnFilter('', spalte);
            }
        });
    };

    var Schnellfilter = function Schnellfilter(element) {
        var $element = $(element);

        var selectClass = $element.data('filter-selectclass'); // hier class kleingeschrieben
        var spalte = $element.data('filter-spalte');
        var query = $element.data('filter-query');
        var dataTableSelector = $element.data('filter-datatable'); // hier kleingeschrieben


        if (typeof query == "undefined" || typeof spalte == "undefined" || typeof dataTableSelector == "undefined") {
            return;
        }

        if (typeof groups[dataTableSelector] == "undefined") {
            groups[dataTableSelector] = {};
        }

        if (typeof groups[dataTableSelector][spalte] == "undefined") {
            groups[dataTableSelector][spalte] = new Array();
        }

        if (groups[dataTableSelector][spalte].indexOf(element) < 0) {
            groups[dataTableSelector][spalte].push(element);
        }

        $element.click(function () {
            if ($(element).hasClass(selectClass) == false) {
                SchnellfilterReset(dataTableSelector, spalte);

                $(element).removeClass('grey');
                $(element).addClass(selectClass);

                $(dataTableSelector).dataTable({ "bRetrieve": true }).fnFilter(query, spalte, true);
            } else {
                SchnellfilterReset(dataTableSelector, spalte);
            }
        });
    };

    window.schnellfilter = Schnellfilter;

    $(document).ready(function () {
        $('.schnellfilter').each(function () {
            schnellfilter(this);
        });
    });
}(window.jQuery);

/**
 * Erweiterungen für DataTable
 */
if (typeof $.fn.dataTableExt !== "undefined") {
    $.fn.dataTableExt.oApi.fnStandingRedraw = function (oSettings) {
        if (oSettings != null) {
            if (oSettings.oFeatures.bServerSide === false) {
                var before = oSettings._iDisplayStart;

                oSettings.oApi._fnReDraw(oSettings);

                // iDisplayStart has been reset to zero - so lets change it back
                oSettings._iDisplayStart = before;
                oSettings.oApi._fnCalculateEnd(oSettings);
            }

            // draw the 'current' page
            oSettings.oApi._fnDraw(oSettings);
        }
    };
    $.fn.dataTableExt.oApi.fnFindCellRowIndexes = function (oSettings, sSearch, iColumn) {
        var i,
            iLen,
            j,
            jLen,
            aOut = [],
            aData;

        for (i = 0, iLen = oSettings.aoData.length; i < iLen; i++) {
            aData = oSettings.aoData[i]._aData;

            if (typeof iColumn == 'undefined') {
                for (j = 0, jLen = aData.length; j < jLen; j++) {
                    if (aData[j] == sSearch) {
                        aOut.push(i);
                    }
                }
            } else if (aData[iColumn] == sSearch) {
                aOut.push(i);
            }
        }

        return aOut;
    };
    $.fn.dataTableExt.oApi.fnFindCellRowNodes = function (oSettings, sSearch, iColumn) {
        var i,
            iLen,
            j,
            jLen,
            aOut = [],
            aData;

        for (i = 0, iLen = oSettings.aoData.length; i < iLen; i++) {
            aData = oSettings.aoData[i]._aData;

            if (typeof iColumn == 'undefined') {
                for (j = 0, jLen = aData.length; j < jLen; j++) {
                    if (aData[j] == sSearch) {
                        aOut.push(oSettings.aoData[i].nTr);
                    }
                }
            } else if (aData[iColumn] == sSearch) {
                aOut.push(oSettings.aoData[i].nTr);
            }
        }

        return aOut;
    };

    $.fn.dataTableExt.oApi.fnGetSelectedIndex = function (oSettings) {
        if (oSettings && oSettings.iLastClickedRowData) {
            return oSettings.iLastClickedRowIndex;
        }
        return null;
    };
    $.fn.dataTableExt.oApi.fnGetSelectedColumnData = function (oSettings, iColNr) {
        if (oSettings && oSettings.iLastClickedRowData) {
            var idx = oSettings.iLastClickedRowIndex;
            return oSettings.aoData[idx]._aData[iColNr];
        }
        return null;
    };
    $.fn.dataTableExt.oApi.fnLfdnrToRowIndex = function (oSettings, lfdnr) {
        if (oSettings && !isNaN(lfdnr)) {
            var idx = 0;
            for (var iLen = oSettings.aoData.length; idx < iLen; idx++) {
                if (oSettings.aoData[idx]._aData[0] === lfdnr) {
                    break;
                }
            }
            return idx;
        }
        return null;
    };
    $.fn.dataTableExt.oApi.fnFocusRowByIndex = function (oSettings, idx) {
        if (oSettings) {
            // console.log(oSettings.aoData[0].anCells[1]);
            var iColIdx = parseInt(idx);
            if (iColIdx < oSettings.aoData.length) {
                $(oSettings.aoData[iColIdx].anCells[1]).click();
            }
        }
    };
};
